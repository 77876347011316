<template>
  <v-layout>
    <v-data-table :headers="headers" :items="items" :server-items-length="total" :options.sync="options"
      :loading="loading" class="elevation-1 align-self-start col-12 pa-0" item-key="id">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title class="d-none d-sm-flex">Protocolos</v-toolbar-title>

          <v-divider class="mx-3 d-none d-sm-flex" inset vertical></v-divider>

          <v-spacer class="d-none d-sm-flex"></v-spacer>

          <v-dialog ref="dialogDesde" v-model="dialogDesde" :return-value.sync="desde" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :small="isMobile" rounded color="primary" dark v-bind="attrs" v-on="on">
                Desde: {{computedDesdeFormatted}}
              </v-btn>
            </template>
            <v-date-picker v-model="desde" :max="today" @change="setDesde()" scrollable>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn text color="primary" @click="dialogDesde = false">Cancelar</v-btn>
            </v-date-picker>
          </v-dialog>

          <v-divider class="mx-3" inset vertical></v-divider>

          <v-dialog ref="dialogHasta" v-model="dialogHasta" :return-value.sync="hasta" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :small="isMobile" rounded color="primary" dark v-bind="attrs" v-on="on">
                Hasta: {{computedHastaFormatted}}
              </v-btn>
            </template>
            <v-date-picker v-model="hasta" :max="today" @change="setHasta()" scrollable>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn text color="primary" @click="dialogHasta = false">Cancelar</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.descargar="{ item }">
        <v-icon color="info" class="ml-4" @click="download(item)" aria-label="Descargar" title="Descargar">
          mdi-download
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h4">
          Aviso
        </v-card-title>
        <v-card-text class="text-h6">
          Para visualizar sus resultados deberá completar por única vez nuestra encuesta anual, la cual es anónima y no
          toma más de 30 segundos en completarse. De esta forma podremos conocer mejor sus necesidades y expectativas
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="goToSurvey()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>

</template>

<script>

import moment from "moment";

export default {
  data: () => ({
    loading: false,
    dialog: false,
    headers: [
      { text: 'Nombre', value: 'NombreArchivo', sortable: false },
      { text: 'Fecha', value: 'FechaResultado', sortable: false },
      { text: 'Propietario', value: 'Propietario', sortable: false },
      { text: '', value: 'descargar', align: 'right', sortable: false },
    ],
    items: [],
    total: 0,
    options: {},
    desde: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    hasta: moment().format('YYYY-MM-DD'),
    filtro: {
      desde: null,
      hasta: null,
      pagina_solicitada: 1,
      registrosPorPagina: 10,
    },
    dialogDesde: false,
    dialogHasta: false,
    today: moment().format('YYYY-MM-DD'),
  }),

  computed: {
    computedDesdeFormatted () {
      if (this.isMobile) {
        return this.desde ? moment(this.desde).format('DD/MM/YY') : ''
      }

      return this.desde ? moment(this.desde).format('DD/MM/YYYY') : ''
    },
    computedHastaFormatted () {
      if (this.isMobile) {
        return this.hasta ? moment(this.hasta).format('DD/MM/YY') : ''
      }

      return this.hasta ? moment(this.hasta).format('DD/MM/YYYY') : ''
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    }
  },

  watch: {
    options: {
      handler () {
        this.query()
      },
      deep: true,
    },
  },

  methods: {
    refresh: function () {
      this.options.page = 1
      this.query()
    },

    query: function () {
      this.loading = true

      this.filtro.desde = moment(this.desde).format('YYYY-DD-MM')
      this.filtro.hasta = moment(this.hasta).format('YYYY-DD-MM')
      this.filtro.pagina_solicitada = this.options.page
      this.filtro.registrosPorPagina = this.options.itemsPerPage

      if (sessionStorage.getItem('verEncuesta') === 'S') {
        this.loading = false
        this.dialog = true
        return
      }

      this.$soapClient.call('listarProtocolos', {
        filtro: this.filtro,
      })
        .then((response) => {
          this.items = response.data.resultado
          this.total = response.data.filtros.totalRegistros
        })
        .catch((error) => {
          if ('message' in error) {
            this.$eventHub.$emit('snackbar-message', error.message, 'error')
          } else {
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
          }
        })
        .then(() => {
          this.loading = false
        })
    },

    setDesde () {
      this.desdeDialog = false;
      this.$refs.dialogDesde.save(this.desde);
      this.refresh();
    },

    setHasta () {
      this.hastaDialog = false;
      this.$refs.dialogHasta.save(this.hasta);
      this.refresh();
    },

    download (item) {
      let link = document.createElement("a");
      link.setAttribute('download', item.NombreArchivo);
      link.href = item.pdf;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    goToSurvey() {
      const laboratory = sessionStorage.getItem('laboratorio')

      if (laboratory == 'B') {
        this.$router.push({name: 'encuestaBromatologia'})
      } else {
        this.$router.push({name: 'encuestaVeterinaria'})
      }

    }
  },
};
</script>
